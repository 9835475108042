<template>
<div id="main-wrap">
	<div class="top-banner-wrap">
		<div style="padding-right:220px">
			<div class="sub-title">국제뷰티전문가협회와 함께하는 혁신과 성장의 여정</div>
			<div class="main-title">글로벌 한국 뷰티산업의 미래를 선도하다</div>
            <div class="photo-watermark">Photo: joanna kosinska</div>
		</div>
	</div>
	<div class="second-sec">
		<swiper
			:modules="modules" 
			:autoplay="{delay:4000, paus1OnMouseEnter:true }"
			:navigation="isNavigation"
			:pagination="{ clickable: true }"
			:loop="true"
			@swiper="onSwiper"
			@slideChange="onSlideChange"
			@mouseover="onMouseOver"
			@mouseleave="onMouseLeave"
		>
			<swiper-slide>
				<div class="cont-wrap">
					<div class="desc-wrap">
						<div class="txt">국제뷰티전문가협회는 글로벌 시장에서의 경쟁력을 강화하기 위한 <br>다양한 프로그램과 네트워킹 기회를 제공하여 <br>한국 뷰티의 위상을 높이고 있습니다.</div>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide>
				<div class="cont-wrap">
					<div class="desc-wrap">
						<div class="txt">우리는 뷰티 업계가 직면한 공통의 과제들을 해결하는 데 힘쓰는 비영리 단체입니다. 우리 조직은 업계의 이미지를 개선하고 평판을 높이는 데 주력하고 있습니다.</div>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide>
				<div class="cont-wrap">
					<div class="desc-wrap">
						<div class="txt">우리는 국내는 물론 국제적인 뷰티의 가치를 높이기 위해 연구 합니다.</div>
					</div>
				</div>
			</swiper-slide>

		</swiper>
	</div>
	<div class="third-sec" >
		<div class="cont-wrap">
			<div class="main-title">Partners</div>
			<ul class="desc">
				<li>우리는 의미있는 변화를 위한 집단 행동의 중요성을 깨달은 업계 최고의 전문가 그룹에 의해 2024년 설립되었습니다.</li>
				<li>우리는 호주기독대학과 캐나다크리스챤대학원대학교와 함께 하며 미용인들의 발전을 위해 노력할 것입니다.</li>
			</ul>
			<div class="site-wrap">
				<div class="bg-wrap column1">
					<div class="hover_bg"></div>
					<div class="title">캐나다 대학교</div>
				</div>
				<div class="bg-wrap column2">
					<div class="hover_bg"></div>
					<div class="title">호주대학교</div>
				</div>
				<div class="bg-wrap column3">
					<div class="hover_bg"></div>
					<div class="title">평생교육원</div>
				</div>
				<div class="bg-wrap column4">
					<div class="hover_bg"></div>
					<div class="title">학회</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>


<style scoped>
@import "../assets/scss/main.scss";
</style>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay, EffectFade} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default {
    components: {
		Swiper,
		SwiperSlide,
    },
    setup() {
		let isNavigation = ref(false);
		// const onSwiper = (swiper) => {
		const onSwiper = () => {
		};
		const onSlideChange = () => {
			// console.log('slide change');
		};
		const onMouseOver = () =>{
			isNavigation.value = true;
		}
		const onMouseLeave = () =>{
			isNavigation.value = false;

		}
		return {
			onSwiper,
			onSlideChange,
			onMouseOver,
			onMouseLeave,
			isNavigation,
			modules: [Navigation, Pagination,  Autoplay, EffectFade ],
		};
	},
};
</script>