import { createWebHistory, createRouter } from "vue-router";
import MainComponent from "@components/MainComponent.vue";
import DivisionComponent from "@components/w/DivisionComponent.vue";
import TechnicalComponent from "@components/w/TechnicalComponent.vue";
import DirectorsComponent from "@components/w/DirectorsComponent.vue";
import CertificateComponent from "@components/w/CertificateComponent.vue";
import AssociationComponent from "@components/w/AssociationComponent.vue";
import AdvisoryComponent from "@components/w/AdvisoryComponent.vue";

import PersonComponent from "@components/p/PersonComponent.vue";

import FaqComponent from "@components/n/FaqComponent.vue";
import NoticeComponent from "@components/n/NoticeComponent.vue";

import PolicyComponent from "@components/h/PolicyComponent.vue";
import GrowthComponent from "@components/h/GrowthComponent.vue";

import SeminarComponent from "@components/e/SeminarComponent.vue";
import CompetitionComponent from "@components/e/CompetitionComponent.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: MainComponent,
    },
    {
        path: "/w/divison",  // DivisionConponent.vue로 이동할 Path
        name: "division",  // router name
        component: DivisionComponent,  // Path로 이동될 Component
    },
    {
        path: "/w/technical", 
        name: "technical", 
        component: TechnicalComponent, 

    },
    {
        path: "/w/directors", 
        name: "directors", 
        component: DirectorsComponent, 

    },
    {
        path: "/w/certificate",
        name: "certificate",
        component: CertificateComponent, 

    },
    {
        path: "/w/association", 
        name: "association",
        component: AssociationComponent,

    },
    {
        path: "/w/advisory", 
        name: "advisory",
        component: AdvisoryComponent,

    },
    {
        path: "/h/growth", 
        name: "growth",
        component: GrowthComponent,
    },
    {
        path: "/h/policy", 
        name: "policy",
        component: PolicyComponent,
    },
    {
        path: "/p/person", 
        name: "person",
        component: PersonComponent,
    },
    {
        path: "/e/seminar", 
        name: "seminar",
        component: SeminarComponent,
    },
    {
        path: "/e/competition", 
        name: "competition",
        component: CompetitionComponent,
    },
    {
        path: "/n/notice", 
        name: "notice",
        component: NoticeComponent,
    },
    {
        path: "/n/faq", 
        name: "faq",
        component: FaqComponent,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "route-active",
    linkExactActiveClass: "route-active"
});

export default router;