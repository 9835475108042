<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div class="txt">아름다운연합, 함께하는 세상 만들기
아름다움을 뒷받침하는 신뢰, 모든 뷰티인과 함께 합니다. 연합은 각 개인과 단체의 목표를 넘어 공동의 목표를 위해 노력하는 것을 의미합니다. 이를 통해 우리는 더 큰 영향력을 발휘하고 더 나은 세상을 만들 수
있습니다.</div>
			</div>
		</div>
	</div>
	<div class="third-sec" >
		<div class="cont-wrap">
			<div class="main-title">홍보배너 넣기</div>
			<div class="site-wrap">
				<div class="bg-wrap column1">
					<div class="hover_bg"></div>
					<div class="title">캐나다 대학교</div>
				</div>
				<div class="bg-wrap column2">
					<div class="hover_bg"></div>
					<div class="title">호주대학교</div>
				</div>
				<div class="bg-wrap column3">
					<div class="hover_bg"></div>
					<div class="title">평생교육원</div>
				</div>
				<div class="bg-wrap column4">
					<div class="hover_bg"></div>
					<div class="title">학회</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
