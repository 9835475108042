<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div class="txt">
					뷰티업계의 기술발전을 위해 노력합니다. 
				</div>
				<div class="txt">
					뷰티의 미래에 영향을 미치려고 노력합니다.
				</div>
				<div class="txt"> 
					교육&세미나를 통한 기술전수 및 발전을위해 노력합니다.
				</div>
			</div>
		</div>
	</div>
	<div class="third-sec" >
		<div class="cont-wrap">
			<div class="main-title">기술위원회</div>
			<div class="img-cont-wrap">
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
