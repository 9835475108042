<template>
<div id="power-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div>인재</div>
				<div class="txt">국내 및 국제적인 기술자와 학회를 통해 더욱 성장 발전시키는 위원회입니다. 기술과 전문지식을 활용하여 국내 및 국제적인 활동을 할 수 있도록 지속적으로 함께합니다. 성공적인 비즈니스를 구출할 수 있도록 기회를 제공합니다.</div>
			</div>
		</div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div>미래인재프로그램</div>
				<div class="txt">협회는 뷰티,미용업계의 학생들에게 업계의 다양한 분야의 경로를 소개하고 홍보하여, 좀 더 발전적인 미래를 함께하기위해 노력합니다. 또한 새로운 인재를 지속적으로 유입시켜 미용산업의 글로벌 명성을 유지하는것을 목표로 하고 있습니다.</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/power.scss";
</style>
