<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계를 선도하는</div>
			<div class="sub-title">글로벌인재</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div class="txt">
					<ul>
						<li>전략적 의사 결정과 조직적 방향 및 지침을 담당하고 위원회의 목표와 목표 달성을 추진</li>
						<li>업계 최고의 인사들로 구성</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="third-sec" >
		<div class="cont-wrap">
			<div class="main-title">이사회&리더쉽</div>
			<div class="img-cont-wrap">
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
