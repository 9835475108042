<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div class="txt">대회</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/event.scss";
</style>
