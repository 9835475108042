<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="second-sec">
		<div class="cont-wrap">
			<div class="desc-wrap">
				<div class="txt">
                    <div>영향력있는 인사들로 구성된 국제뷰티전문가협회 자문위원회는 조직의 업무, 사명, 비전을 지원하고 이사회와 리더쉽 팀이 정한 전략적 목표와 목적을 보장하기 위해 협력합니다.</div>
					<div>
						협회의 목표와 목적을 발전시키기 위해 주요 정책 문제를 식별하여 영향을
미칩니다. -뷰티분야의 긍정적인 변화를 일으키기 위해 노력합니다. -협회 개인회원의 지식과기술 분야 등 각 부분에서의 발전을 위해 함께합니다. -업계에서 성공하기 위한 지식, 자원 및 지원을 갖추도록 연구하며 노력합니다. 
					</div>
                </div>
			</div>
		</div>
	</div>
	<div class="third-sec" >
		<div class="cont-wrap">
			<div class="main-title">자문위원회</div>
			<div class="img-cont-wrap">
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/person_sample.jpg">
					<div class="name">박은선</div>
					<div class="desc">나는 이러이러하다 그리고 또 이러이러하다. 하지만 이렇게도 한다.</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
