<template>
    <div id="footer-wrap" style="">
        <div class="first-sec">
            <div class="title">Contact us online</div>
            <div class="sub-title">Press enquiries</div>
            <div>xxxxxxx@domain.com</div>
        </div>
        <div class="second-sec">
            <div class="title">Contact us in person</div>
            <div class="sub-title">Phone : +82 0000000</div>
            <div>address : axxxxxx</div>
        </div>
  
    </div>
</template>
<style scoped>
@import "../assets/scss/footer.scss";
</style>