<template>
    <header>
        <a href="javascript:;" @click="goAnotherPage('main', {page:'',pageCate:''})" class="logo"><span><img src="../assets/logo.png" style="vertical-align: middle;"></span><span style="padding-left:5px;vertical-align: bottom;">국제뷰티지도자협회</span></a>
        <nav class="pc-menu-wrap">
            <span>
                <a href="javascript:;" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.pageCate == 'w'}" >우리는 누구인가</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w1'}">이사회 & 리더쉽</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('technical', {page:'w6',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w6'}">기술위원회</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('advisory', {page:'w2',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w2'}">자문위원회</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('division', {page:'w3',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w3'}">분과위원회</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('association', {page:'w4',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w4'}">아름다운연합</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('certificate', {page:'w5',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w5'}">자격관리</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('policy', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.pageCate == 'h'}">핵심원칙</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('policy', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h1'}">정책 및 영향</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('growth', {page:'h2',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h2'}">성장</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('person', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.pageCate == 'p'}" >개인회원</a>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('seminar', {page:'e1',pageCate:'e'})" :class="{'route-active':menuObj.pageCate == 'e'}" >이벤트</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('seminar', {page:'e1',pageCate:'e'})" :class="{'route-active':menuObj.page == 'e1'}">세미나</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('competition', {page:'e2',pageCate:'e'})" :class="{'route-active':menuObj.page == 'e2'}">대회</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.pageCate == 'n'}">소식</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n1'}">공지사항</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('faq', {page:'n2',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n2'}">FAQ</a></li>
                </ul>
            </span>
        </nav>
        <nav class="mobile-menu-wrap">
            <div class="mobile-menu">
                <button class="burger burger2" @click="showMobileMenu" :class="isShowMenu ? 'on' : ''">
                    <span></span>
                </button>
            </div>
            <div class="sub-menu-wrap" :class="{active:isShowMenu}">
                <Transition name="display" mode="out-in" >
                    <div class="sub-menu-cont"  v-if="isShowMenu" >
                        <router-link :to="{name:'advisory'}" class="main-title" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.pageCate == 'w'}">우리는 누구인가</router-link>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w1'}"> - 이사회 & 리더쉽</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('advisory', {page:'w2',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w2'}"> - 자문원회</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('division', {page:'w3',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w3'}"> - 분과위원회</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('association', {page:'w4',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w4'}"> - 아름다운연합</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('certificate', {page:'w5',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w5'}"> - 자격관리</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('policy', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.pageCate == 'h'}">핵심원칙</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('policy', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h1'}"> - 정책 및 영향</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('growth', {page:'h2',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h2'}"> - 성장</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('person', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.pageCate == 'p'}" >개인회원</a>
                        <a href="javascript:;" @click="goAnotherPage('seminar', {page:'e1',pageCate:'e'})" :class="{'route-active':menuObj.pageCate == 'e'}" >이벤트</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('seminar', {page:'e1',pageCate:'e'})" :class="{'route-active':menuObj.page == 'e1'}"> - 세미나</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('competition', {page:'e2',pageCate:'e'})" :class="{'route-active':menuObj.page == 'e2'}"> - 대회</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.pageCate == 'n'}">소식</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n1'}"> - 공지사항</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('notice', {page:'n2',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n2'}"> - FAQ</a></li>
                        </ul>
                    </div>
                </Transition>
            </div>
        </nav>
    </header>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter,useRoute  } from 'vue-router';

const router = useRouter();
const route = useRoute();

let menuObj = ref({page:'',pageCate:''});
watch(route,() =>{
    const dataObj = history.state; // 이렇게 받는다.
    if(dataObj.dataObj !== undefined && dataObj.dataObj.page !== undefined) menuObj.value = dataObj.dataObj;
    if(isShowMenu.value) isShowMenu.value = false;
});

function goAnotherPage(_name, _params){
    router.push({
        name: _name,
        state: {
            dataObj : _params,
        }
    });
}
let isShowMenu = ref(0);
function showMobileMenu(){
    isShowMenu.value = isShowMenu.value ? 0 : 1;
}
</script>